import React, { Component } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import styles from './App.module.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Download from './components/download/Download';
import Login from './components/login/Login';
import axios from 'axios';
import { dataServices } from '@ambita/ambita-components-core';

const { config } = dataServices;

let url;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessCode: '',
      format: '',
      id: null,
      invoiceNumber: '',
      isLoggedIn: false,
      loginFailed: false
    }
  }

  loginHandler = (invoiceNumber, accessCode) => {
    invoiceNumber && accessCode ? this.setState({
      invoiceNumber: invoiceNumber,
      accessCode: accessCode,
      loginFailed: false
    }, () => {
      this.fetchInvoice();
    }) : this.setState({ loginFailed: true, isLoggedIn: false }, () => {
      this.fetchInvoice();
    });
  };

  urlError = () => {
    this.setState({
      isLoggedIn: false,
      loginFailed: true
    })
  };

  downloadUnmount = () => {
    this.setState({ isLoggedIn: false, invoiceNumber: '' });
  };

  fetchInvoice = () => {
    axios.get(`${url}/billing/v1/invoices`, {
      params: {
        invoiceNumber: this.state.invoiceNumber,
        accessCode: this.state.accessCode
      }
    }).then((response) => {
      this.setState({
        id: response.data.id,
        isLoggedIn: true,
        loginFailed: false
      })
    }).catch((error) => {
      console.log('error: ', error);
      this.setState({
        isLoggedIn: false,
        loginFailed: true
      });
    });
  };

  componentDidMount() {
    url = config.isProduction() ? config.PROD_API_HOST : config.BETA_API_HOST;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const invoiceNumber = urlSearchParams.get('invoiceNumber')
    const accessCode = urlSearchParams.get('accessCode')
    if (invoiceNumber && accessCode) {
      this.loginHandler(invoiceNumber, accessCode)
    }
  }

  render() {
    return (
      <Router>
        <div className={styles.container}>
          <Header/>
          <div className={styles.content}>
            <Routes>
              {!this.state.isLoggedIn && (
                <Route 
                  exact path="/" 
                  element={ 
                    <Login 
                      invoiceNumber={this.state.invoiceNumber}
                      accessCode={this.state.accessCode}
                      loginHandler={this.loginHandler}
                      loginFailed={this.state.loginFailed}/>
                  }
                />
              )}
              {this.state.isLoggedIn && (
                <Route 
                  exact path={"/"} 
                  element={
                    <Download
                      url={url}
                      urlError={this.urlError}
                      id={this.state.id}
                      invoiceNumber={this.state.invoiceNumber}
                      accessCode={this.state.accessCode}
                      isLoggedIn={this.state.isLoggedIn}
                      downloadUnmount={this.downloadUnmount}/>
                  }
                />
              )}
            </Routes>
          </div>
          <Footer/>
        </div>
      </Router>
    );
  }
};
